import { Component, HostListener } from "@angular/core";
import { authConfig } from "./shared/sso-oauth2-oidc/auth.config";
import { OAuthService, NullValidationHandler } from "angular-oauth2-oidc";
import { FuseSplashScreenService } from "./shared/loading-screen.service";
import { AccountService } from "./shared/auth/account.service";
import { SetupAdministrationService } from "./pages/setup-administration/setup-administration.service"
@Component({
  selector: "app-root",
  templateUrl: "app.component.html"
})
export class AppComponent {
  title = "IdentityUI";
  endOnBrowserClose: boolean = false;
  constructor(
    private oauthService: OAuthService,
    private fuseSplashScreenService: FuseSplashScreenService,
    private account: AccountService,
    public service: SetupAdministrationService,
  ) {
    this.configureWithoutDiscovery();
    if (!this.oauthService.hasValidAccessToken()) {
      this.oauthService.initImplicitFlow();
    } else {
      this.account
        .get()
        .toPromise()
        .then(account => {
          account.response["isSODUser"] = "YES"
          localStorage.setItem(
            "currentLoginUser",
            JSON.stringify(account.response)
          );
          localStorage.setItem("userId", account.response._id);
          localStorage.setItem("clientId", account.response.customer);
        })
        .catch(err => {
          return null;
        });
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    setTimeout(()=>{
      this.service.getSessiondetails().subscribe((data: any) => {
        if (data.length == 0) {
          this.endOnBrowserClose = false;
        }
        else {
          if (data[0].endOnBrowserClose) { //clear token ?
            this.oauthService.logOut(false);
          }
        }
      })
    }, 5000)
  }

  private configureWithoutDiscovery() {
    //this.oauthService.configure(authConfig);
    this.oauthService.configure(authConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    this.oauthService.tryLogin({
      disableOAuth2StateCheck: true
    });
  }
}
