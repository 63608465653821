import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Observable } from "rxjs/Observable";
import { saveAs } from "file-saver";
import { utils, write, WorkBook, WorkSheet } from "xlsx/dist/xlsx.min.js";
import { environment } from "../../../environments/environment";
import { observable } from "rxjs";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
  providedIn: "root",
})
export class SetupAdministrationService {
  onOrdersChanged: BehaviorSubject<any>;
  constructor(private httpClient: HttpClient) {
    this.onOrdersChanged = new BehaviorSubject({});
  }
  baseURL = environment.baseUrl + "identity/v1/"; // "http://sentri-idendity-api.us-east-1.elasticbeanstalk.com/identity/v1/";
  sodURL = environment.sodURL;
  // baseURL: string = "http://localhost:80/identity/v1/";
  // sodURL = "http://localhost:9020/api/";
  //  IDMgmtBaseURL: string = "http://localhost:81/identity/v1/";
  public getExistingUserList(clientId: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}requestaccess/userList/` + clientId,
      { observe: "response" }
    );
  }
  public getFusionConflictList(
    datasource: any,
    clientId: any,
    userName: any,
    roles: any
  ): Observable<any> {
    // let uName = [];
    // uName.push(userName);
    //let role = [];
    //role.push(roles);
    var params: any = {};
    params.productName = "FUSION";
    params.datasource = datasource;
    params.isIdentity = true;
    // params.clientId = clientId;
    params.userName = JSON.stringify(userName);
    params.role = JSON.stringify(roles);
    return this.httpClient.get<any>(
      `${this.sodURL}userConflicts/groupedUserReports`,
      {
        params,
        observe: "response",
      }
    );
  }
  public getEBSuserconflict(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}reports/ebsuserconflict/`,

      { observe: "response" }
    );
  }
  public getuserassignments(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}reports/userassignment/`,

      { observe: "response" }
    );
  }
  public getroleaccess(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}reports/roleaccesslist/`,

      { observe: "response" }
    );
  }
  public getuseraccess(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}reports/useraccessreports/`,

      { observe: "response" }
    );
  }
  public getuserlistreports(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}reports/userlistreports/`,

      { observe: "response" }
    );
  }
  public getEBSroleconflict(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}reports/ebsroleconflict/`,

      { observe: "response" }
    );
  }
  public maindashboard(clientId): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}dashboard/maindashboardval/`+clientId,

      { observe: "response" }
    );
  }
  public INactiveuserlist(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}dashboard/getinactiveusers/`,

      { observe: "response" }
    );
  }
  public activelist(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}dashboard/Getactivelist/`,

      { observe: "response" }
    );
  }
  public Inactivelist(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}dashboard/GetINactivelist/`,

      { observe: "response" }
    );
  }
  public userwomanager(clientid,data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}dashboard/Getmanagerlist/`,data,

      { observe: "response" }
    );
  }
 
  public Rolesactivelist(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}dashboard/Getroleslist/`,

      { observe: "response" }
    );
  }
  public Entitlementlist(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}dashboard/Getentitlement/`,

      { observe: "response" }
    );
  }
  public pendingapprovalcount(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}dashboard/Getpendingapprovelist/`,

      { observe: "response" }
    );
  }
  public campginoverall(clientId): Observable<any> {
    return this.httpClient.get<any>(`${this.baseURL}campaign/getcounts/${clientId}`, {
      observe: "response",
    });
  }
  public totalcertificate(data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}campaign/gettotalcount/`,
      data,

      { observe: "response" }
    );
  }
  public customcertificate(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}campaign/getcustomcampign/`,

      { observe: "response" }
    );
  }
  public listcertificate(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}campaign/getcampigntype/`,

      { observe: "response" }
    );
  }
  public pendingcertificate(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}campaign/getpendingcampign/`,

      { observe: "response" }
    );
  }
  public getDatasourceList(clientId: any, productName: any) {
    var params: any = {};
    // params.clientId = clientId;
    params.isIdentity = true;
    params.productName = productName;
    return this.httpClient.get<any>(`${this.sodURL}datasources`, {
      params,
      observe: "response",
    });
  }
  public getControls(params: any) {
    return this.httpClient.get<any>(
      `${this.sodURL}accessControls/controlByDs/`,
      {
        params,
        observe: "response",
      }
    );
  }

  public searchbyid(data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}reports/getbyId/`,
      data,

      { observe: "response" }
    );
  }
  public createreportname(data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}reports/getreportname/`,
      data,
      { observe: "response" }
    );
  }

  public saveSessionDetails(data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}systemAdministration/saveSessionDetails/`,
      data,
      { observe: "response" }
    );
  }

  public getSessiondetails(): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}systemAdministration/getSessiondetails/`,
      {},
      { observe: "response" }
    );
  } 
  
  public refreshToken(data :any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}systemAdministration/refreshToken/`, data,
      { observe: "response" }
    );
  } 
  public searchbycate(data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}reports/getbytype/`,
      data,

      { observe: "response" }
    );
  }
  public viewreports(data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}reports/viewreport/`,
      data,

      { observe: "response" }
    );
  }

  public getPrivateReport(id, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}reports/getPrivateReport/`,
      data,
      { observe: "response" }
    );
  }

  public getPublicReport(id, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}reports/getPublicReport/`,
      data,
      { observe: "response" }
    );
  }

  public savereports(data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}reports/createreport/`,
      data,

      { observe: "response" }
    );
  }
  public getuserrequestreport(clientId, data): Observable<any> {
    data['clientId'] = clientId
    return this.httpClient.post<any>(
      `${this.baseURL}reports/userrequestreport/`,
      data,

      { observe: "response" }
    );
  }
  public getuseraccessreport(clientId, data): Observable<any> {
    data['clientId'] = clientId
    return this.httpClient.post<any>(
      `${this.baseURL}reports/useraccessreport/`,
      data,

      { observe: "response" }
    );
  }
  public getRoleMembershipReport(clientId, data): Observable<any> {
    data['clientId'] = clientId
    return this.httpClient.post<any>(
      `${this.baseURL}reports/getRoleMembershipReport/`,
      data,

      { observe: "response" }
    );
  }
  public getpendingApprovalreport(clientId, data): Observable<any> {
    data['clientId'] = clientId
    return this.httpClient.post<any>(
      `${this.baseURL}reports/pendingapprovalreport/`,
      data,

      { observe: "response" }
    );
  }
  public getuserreport(clientId, data): Observable<any> {
    data['clientId'] = clientId
    return this.httpClient.post<any>(
      `${this.baseURL}reports/userreport/`,
      data,

      { observe: "response" }
    );
  }
  public getuserstatus(clientId, data): Observable<any> {
    data['clientId'] = clientId
    return this.httpClient.post<any>(
      `${this.baseURL}reports/disableuser/`,
      data,
      { observe: "response" }
    );
  }
  public getUserWithoutProvision(clientId, data): Observable<any> {
    data['clientId'] = clientId
    return this.httpClient.post<any>(
      `${this.baseURL}reports/userwithoutprovision/`,
      data,
      { observe: "response" }
    );
  }
  public gettargetList(clientId,data): Observable<any> {
    data['clientId'] = clientId
    return this.httpClient.post<any>(
      `${this.baseURL}reports/targetlist/`,
      data,
      { observe: "response" }
    );
  }
  public getPwdPolicyList(clientId,data): Observable<any> {
    data['clientId'] = clientId
    return this.httpClient.post<any>(
      `${this.baseURL}reports/pwdpolicy/`,
      data,
      { observe: "response" }
    );
  }
  public getRolessentri(clientId, data): Observable<any> {
    data['clientId'] = clientId
    return this.httpClient.post<any>(
      `${this.baseURL}reports/sentriroles/`,
      data,
      {
        observe: "response",
      }
    );
  }
  public getentitle(clientId, data): Observable<any> {
    data['clientId'] = clientId
    return this.httpClient.post<any>(
      `${this.baseURL}reports/entitlement/`,
      data,
      {
        observe: "response",
      }
    );
  }
  public exportdata(data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}reports/exportdata/`,
      data,

      { observe: "response" }
    );
  }

  public getreportlist(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}reports/getreportlist/`,

      { observe: "response" }
    );
  }
  public getsourcelist(clientId, data): Observable<any> {
    data['clientId'] = clientId
    return this.httpClient.post<any>(
      `${this.baseURL}reports/getroles/`,
      data,

      { observe: "response" }
    );
  }
  public getAllusercreate(params): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}roleaccess/getusercreate/` + params.searchVal,

      { observe: "response" }
    );
  }
  public getTargetmapping(clientId: any, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}targetmapping/targetmap/`+ clientId ,
      data,
      { observe: "response" }
    );
  }
  public getApprovedhistory(clientId: any, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}requestaccess/getHistory/` + clientId,
      data,
      { observe: "response" }
    );
  }
  public accessEntitlementNamecheck(params): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}accessentitlement/namechecking`,
      params,
      {
        observe: "response",
      }
    );
  }
  public getuserIdList(data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}setupadmin/getuserid/`,
      data,
      { observe: "response" }
    );
  }

  public getuserIdPolicyList(clientId: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}setupadmin/userIdpolicy/` + clientId,
      { observe: "response" }
    );
  }

  public newGetUserIdList(clientId, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}setupadmin/getuserid/`,
      data,
      { observe: "response" }
    );
  }
  public edituserid(data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}setupadmin/edituserid/`,
      data,
      { observe: "response" }
    );
  }
  public deleteuserIdList(data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}setupadmin/deleteuserid/`,
      data,
      { observe: "response" }
    );
  }
  public updateuserIdPolicy(params: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}setupadmin/updateid `,
      params,
      {
        observe: "response",
      }
    );
  }
  public getEbsConflictList(requestId): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}conflicthistory/getConflicts/` + requestId,
      { observe: "response" }
    );
  }
  public updateNotificationConfig(params): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}notification-config/updateNotification`,
      params,
      {
        observe: "response",
      }
    );
  }
  public getNotificationConfig(clientId): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}notification-config/getNotificationConfig/` + clientId,
      {
        observe: "response",
      }
    );
  }
  public deleteNotificationConfig(clientId, notiId): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}notification-config/deleteNotificationConfig/` +
      clientId +
      "/" +
      notiId,
      {},
      {
        observe: "response",
      }
    );
  }
  public getRolesAccessList(id: any, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}roleaccess/client/` + id,
      data,
      {
        observe: "response",
      }
    );
  }
  public getJobList(params): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}roleaccess/jobs/` +
      params.clientId +
      "/" +
      params.searchVal,
      {
        observe: "response",
      }
    );
  }

  public getLocationList(params): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}roleaccess/location/` +
      params.clientId +
      "/" +
      params.searchVal,
      {
        observe: "response",
      }
    );
  }

  public getAllLocationList(params): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}roleaccess/location/` + params.clientId,
      {
        observe: "response",
      }
    );
  }
  public getBusinessUnitList(params): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}roleaccess/businessUnit/` +
      params.clientId +
      "/" +
      params.searchVal,
      {
        observe: "response",
      }
    );
  }
  public getPersonTypeList(params): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}roleaccess/personTypes/` +
      params.clientId +
      "/" +
      params.searchVal,
      {
        observe: "response",
      }
    );
  }
  public getEmpNameList(params): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}roleaccess/empname/` +
      params.clientId +
      "/" +
      params.searchVal,
      {
        observe: "response",
      }
    );
  }

  public getUserNameList(params): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}roleaccess/username/` +
      params.clientId +
      "/" +
      params.searchVal,
      {
        observe: "response",
      }
    );
  }
  public updateUserStatus(params): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}campaign/updateUser`,
      params,
      {
        observe: "response",
      }
    );
  }
  public createCampaign(params): Observable<any> {
    return this.httpClient.post<any>(`${this.baseURL}campaign`, params, {
      observe: "response",
    });
  }
  // http://localhost:81/identity/v1/source/client/accessentitlement/
  public accessEntitlement(params): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}accessentitlement/`,
      params,
      {
        observe: "response",
      }
    );
  }
  public getCampaignRequestListUser(clientId, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}campaign/getCerficationReqListUser/` +
      clientId +
      "/" +
      data.userName +
      "/" +
      data.requestType,
      data,
      {
        observe: "response",
      }
    );
  }
  public getCampaignRequestListRole(clientId, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}campaign/getCerficationReqListRole/` +
      clientId +
      "/" +
      data.userName +
      "/" +
      data.requestType,
      data,
      {
        observe: "response",
      }
    );
  }
  public getRolesByUser(clientId, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}campaign/getroleByUser/` +
      clientId +
      "/" +
      data.userName +
      "/" +
      data.roleType,
      data,
      {
        observe: "response",
      }
    );
  }
  public getCampaignList(clientId, userId): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}campaign/client/` + clientId + "/" + userId,

      {
        observe: "response",
      }
    );
  }
  public getCampaignById(campaignId): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}campaign/getcampaign/` + campaignId,

      {
        observe: "response",
      }
    );
  }
  public getCampaignUsersById(clientId, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}campaign/getcampaignusers/` + clientId,
      data,
      {
        observe: "response",
      }
    );
  }
  public getRoleSummaryByCampaignId(clientId, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}campaign/getRoleSummaryByCampaignId/` + clientId,
      data,
      {
        observe: "response",
      }
    );
  }
  public approveCampaign(params): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}campaign/approve`,
      params,

      {
        observe: "response",
      }
    );
  }
  public rejectCampaign(params): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}campaign/revokePemission`,
      params,

      {
        observe: "response",
      }
    );
  }

  public getunusedUsersforGroups(id: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}usergroup/getunusedusers/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public getUnusedControls( data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}usergroup/getunusedcontrols/`,
      data,
      {
        observe: "response",
      }
    );
  }

  public getUsersforGroups(id: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}usergroup/getusers/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public checkGroupNameExist(clientId: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}usergroup/checkGroupNameExist/` + clientId,
      data,
      {
        observe: "response",
      }
    );
  }

  public getUserGroupsbyApproverType(clientId: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}usergroup/getUserGroupsbyApproverType/` + clientId,
      data,
      {
        observe: "response",
      }
    );
  }

  public getLocation(id: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}usergroup/getLocation/` + id,
      data,
      {
        observe: "response",
      }
    );
  } 

  public getunusedLocation(id: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}usergroup/getunusedLocation/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public getGroupMembers(id: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}usergroup/getGroupMembers/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public getUserGroups(id: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}usergroup/getusergroups/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public upsertUserGroups(id: string, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}usergroup/upsertusergroup/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public upsertAnnouncementBanner(id: string, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}announcementbanner/upsertannouncementbanner/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public dontShowAgain(id: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}announcementbanner/dontShowAgain/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public updateBannerStatus(data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}announcementbanner/changeStatus`,
      data,
      {
        observe: "response",
      }
    );
  }

  public updateSentriRoleStatus(data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}sentriroles/changeStatus`,
      data,
      {
        observe: "response",
      }
    );
  }

  public seenBy(id: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}announcementbanner/seenBy/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public getAllBanner(id: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}announcementbanner/getallbanner/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public getToShowBanner(id: string, userId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}announcementbanner/getToShowBanner/` + id + '/' + userId,
      {
        observe: "response",
      }
    );
  }

  public getToList(id: string, userId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}announcementbanner/getToList/` + id + '/' + userId,
      {
        observe: "response",
      }
    );
  }

  public checkNameExist(id: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}announcementbanner/checkNameExist/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public getBanner(id: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}announcementbanner/getbanner/` + id,
      {
        observe: "response",
      }
    );
  }

  public getEntitlement(id: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}accessentitlement/client/` + id,
      data,

      {
        observe: "response",
      }
    );
  }
  public getAccessSearch(params: any, id: string): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}accessentitlement/getAccessSearch/` + id,
      params,
      {
        observe: "response",
      }
    );
  }
  public getAccess(id): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}accessentitlement/getAccessEdit/` + id,
      {
        observe: "response",
      }
    );
  }

  public accessUpdate(data: any, id: string): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}accessentitlement/getAccessUpdate/` + id,
      data,
      {
        observe: "response",
      }
    );
  }
  public accessUpdateStatus(data: any, id: string): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}accessentitlement/accessUpdateStatus/` + id,
      data,
      {
        observe: "response",
      }
    );
  }
  // end status edit
  public targetUserbyType(targetData): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}endpoint/targetUserbyType`,
      targetData,
      {
        observe: "response",
      }
    );
  }
  
  public getTargetbyProduct(clientId, productId): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}endpoint/getByProduct/${productId}/${clientId}`,
      {
        observe: "response",
      }
    );
  }
  public getRolesUsersList(userData): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}roleaccess/getAlluserList`,
      userData,
      {
        observe: "response",
      }
    );
  }
  public getUsersByRoleList(clientId, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}campaign/userList/` + clientId,
      data,
      {
        observe: "response",
      }
    );
  }
  public getReassignUSerList(clientId, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}campaign/reassignUserList/` + clientId + "/" + data.userName,
      data,
      {
        observe: "response",
      }
    );
  }
  public reAssignApprover(clientId, data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}campaign/reassignUser/` + clientId,
      data,
      {
        observe: "response",
      }
    );
  }
  public getManagersList(clientId, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}campaign/getAllmanagers/` + clientId,
      data,
      {
        observe: "response",
      }
    );
  }
  public getJobsList(clientId,data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}campaign/getJobs/` + clientId,
      data,
      {
        observe: "response",
      }
    );
  }
  public checkCampaignNameExist(data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}campaign/checkNameExist`,
      data,
      {
        observe: "response",
      }
    );
  }
  public createuserIdPolicy(params: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseURL}setupadmin/ `, params, {
      observe: "response",
    });
  }
  public createRoleAccess(params: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseURL}roleaccess/ `, params, {
      observe: "response",
    });
  }
  public createRoleexist(params: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}roleaccess/checkname `,
      params,
      {
        observe: "response",
      }
    );
  }

  public getAllAprovalHistory(clientId: any, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}requestaccess/getAprovalHistory/` + clientId,
      data,
      { observe: "response" }
    );
  }
  public getExistingUserRolesList(clientId: any, data: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}requestaccess/existingRole/` +
      clientId +
      "/" +
      data.userId,
      { observe: "response" }
    );
  }

  public getExistingAccessUserList(clientId: any, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}requestaccess/existingAccess/` + clientId,
      data,
      { observe: "response" }
    );
  }

  public getAllUsersList(clientId: any, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}requestaccess/getAlluserList/` + clientId,
      data,
      { observe: "response" }
    );
  }
  public getRequestHistoryList(clientId: any, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}requestaccess/requestHistory/` + clientId,
      data,
      { observe: "response" }
    );
  }
  // public getRequestHistoryList(clientId: any, data: any): Observable<any> {
  //   return this.httpClient.put<any>(
  //     `${this.baseURL}requestaccess/requestHistory/` + clientId,
  //     data,
  //     { observe: "response" }
  //   );
  // }
  // public getRequestHistoryList(clientId: any, data: any): Observable<any> {
  //   console.log("data sent", clientId, data);
  //   return this.httpClient.put<any>(
  //     `${this.baseURL}requestaccess/requestHistory/` + clientId,
  //     data,
  //     { observe: "response" }
  //   );
  // }
  public getRequestHistoryById(requestAccessId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}requestaccess/requestHistorybyid/` + requestAccessId,
      { observe: "response" }
    );
  }

  public getRequestHistoryTableById(clientID: string, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}requestaccess/requestHistoryTableById/` +
      data.requestAccessId,
      data,
      { observe: "response" }
    );
  }

  public getAwaitingApprovalList(clientId: any, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}requestaccess/awaitingApproval/` + clientId,
      data,
      { observe: "response" }
    );
  }

  public getAwaitingApprovalListbyUser(
    clientId: any,
    data: any
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}requestaccess/awaitingApprovalbyUser/` + clientId,
      data,
      { observe: "response" }
    );
  }

  public getawaitingApprovalhistoryCount(
    clientId: any,
    data: any
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}requestaccess/getawaitingApprovalhistoryCount/` + clientId,
      data,
      { observe: "response" }
    );
  }

  public awaitingApprovalRead(
    clientId: any,
    data: any
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}requestaccess/awaitingApprovalRead/` + clientId,
      data,
      { observe: "response" }
    );
  }

  public getawaitingApprovalHistory(
    targetId: any,
    supervisorId: any,
    requestAccessId: any
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}requestaccess/awaitingApprovalHistory/` +
      targetId +
      "/" +
      requestAccessId,
      { supervisorId: supervisorId },
      { observe: "response" }
    );
  }
  public getRequestHistoryByUser(
    targetId: any,
    supervisorId: any
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}requestaccess/getRequestHistoryByUser/` + targetId,
      { supervisorId: supervisorId },
      { observe: "response" }
    );
  }
  public updateApprovalStatus(
    approvalData: any,
    clientId: string
  ): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}requestaccess/approveReject/` + clientId,
      approvalData,
      { observe: "response" }
    );
  }
  public forwardedRequest(
    approvalData: any,
    clientId: string
  ): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}requestaccess/forwardedRequest/` + clientId,
      approvalData,
      { observe: "response" }
    );
  }
  public createAgentConfig(agentConfigData: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}agentconfig`,
      agentConfigData,
      {
        observe: "response",
      }
    );
  }

  public createSourceOfTruth(sourceData: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseURL}source`, sourceData, {
      observe: "response",
    });
  }
  public checkSourceOfTruth(data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseURL}source/sourcename`, data, {
      observe: "response",
    });
  }

  public gets3file(clientId: any): Observable<any> {
    return this.httpClient.get(
      `${this.baseURL}agentconfig/downloadFromS3/` + clientId,
      { observe: "response",responseType: 'blob' as 'json' }
    );
  }

  public getActiveRoles(clientId: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}roleaccess/activeRoles/` + clientId,

      { observe: "response" }
    );
  }
  public createWorflow(workFlowData: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseURL}workflow/`, workFlowData, {
      observe: "response",
    });
  }
  public checkWorkflowNameExist(data: any): Observable<any> {
    return this.httpClient.put<any>(`${this.baseURL}workflow/checkNameExist`, data, {
      observe: "response",
    });
  }
  public updateWorflow(workFlowData: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseURL}workflow/updateWorkflow`, workFlowData, {
      observe: "response",
    });
  }
  public createWorflowRules(workFlowData: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}workflow/workflowRules/`,
      workFlowData,
      {
        observe: "response",
      }
    );
  }
  
  public getWorflowRulesDetails(id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}workflow/workflowRules/getWorkflowRulesDetails/${id}`,
      {
        observe: "response",
      }
    );
  }

  public updateWorflowStatus(workFlowData: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}workflow/updateStatus`,
      workFlowData,
      {
        observe: "response",
      }
    );
  }

  public getWorflowRules(clientId: any, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}workflow/workflowRules/` + clientId,
      data,
      {
        observe: "response",
      }
    );
  }

  public toggleWorkflowApprovalrules(data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}workflow/toggleWorkflowApprovalrules/`,
      data,
      { observe: "response" }
    );
  } 

  public istoggable(data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}workflow/istoggableWorkflowApprovalrules/`,
      data,
      { observe: "response" }
    );
  }

  public getWorFlowModuleList(id: any): Observable<any> {
    return this.httpClient.get<any>(`${this.baseURL}workflow/workflowModule/`, {
      observe: "response",
    });
  }
  public getWorFlowItemList(id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}workflow/workflowItem/` + id,
      {
        observe: "response",
      }
    );
  }

  public getunusedWorFlowItemList(id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}workflow/unusedworkflowItem/` + id,
      {
        observe: "response",
      }
    );
  }

  public getAccessControlList(ds: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.sodURL}accessControls/controlByDs/` + ds,
      {
        observe: "response",
      }
    );
  }

  public getApplicationList(id: any): Observable<any> {
    return this.httpClient.get<any>(`${this.baseURL}workflow/apps/` + id, {
      observe: "response",
    });
  }
  public getActiveUsersList(id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}workflow/activeUsers/` + id,
      {
        observe: "response",
      }
    );
  }
  public getActiveTargetList(clientId: any, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}endpoint/activeTarget/` + clientId,
      data,
      { observe: "response" }
    );
  }
  /*ud*/
  public getTargetConfigList(clientId: any, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}endpoint/client/` + clientId,
      data,
      { observe: "response" }
    );
  }
  public getManageSystemConfigList(clientId: any, data): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}endpoint/client/` + clientId,
      data,
      { observe: "response" }
    );
  }
  public getUserConfigList(targetId: any,userName:String, clientId:any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}requestaccess/userList/` + targetId + '/'+userName+'/'+clientId,
      { observe: "response" }
    );
  }
  public getRolesList(clientId: any, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}roles/client/` + clientId,
      data,
      {
        observe: "response",
      }
    );
  }

  public getUnusedRolesList(clientId: any, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}roles/clientUnused/` + clientId,
      data,
      {
        observe: "response",
      }
    );
  }

  public getProfileList(clientId: any, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}roles/profile/client/` + clientId,
      data,
      {
        observe: "response",
      }
    );
  }

  public getRoles(clientId: any, data: any, productType: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}roles/client/` + clientId + "/" + productType,
      data,
      {
        observe: "response",
      }
    );
  }

  public getRolesforRequestAccess(clientId: any, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}roles/client/` + clientId + "/" + data.productType,
      data,
      {
        observe: "response",
      }
    );
  }

  public getSeachRolesList(params: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}roles/searchRole/` +
      params.clientId +
      "/" +
      params.searchVal,
      {
        observe: "response",
      }
    );
  }

  public getResponsibilityList(baseURL: any): Observable<any> {
    return this.httpClient.get<any>(`${baseURL}responsibilities/allRespname`, {
      observe: "response",
    });
  }

  public getTargetConfigDetail(targetId: any): Observable<any> {
    return this.httpClient.get<any>(`${this.baseURL}endpoint/` + targetId, {
      observe: "response",
    });
  }

  public updateTargetConfig(
    targetData: any,
    targetId: string
  ): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}endpoint/` + targetId,
      targetData,
      { observe: "response" }
    );
  }
  public createTargetConfig(targetData: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseURL}endpoint`, targetData, {
      observe: "response",
    });
  }

  public getAllSodUsers(): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}endpoint/getAllsodUsers`, 
      {},
      { observe: "response"}
    );
  }

  public checktargetname(targetData: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}endpoint/checkname`,
      targetData,
      {
        observe: "response",
      }
    );
  }
  public deleteTargetEndpoint(targetId: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}endpoint/delete/` + targetId,
      {},
      { observe: "response" }
    );
  }
  public updatetargetStatus(data: any, id: string): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}endpoint/status/` + id,
      data,
      {
        observe: "response",
      }
    );
  }
  public getAllactivesources(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}Source/getallactive/`,

      { observe: "response" }
    );
  }
  public getAllactivetargets(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}targetmapping/getallactivetargets/`,

      { observe: "response" }
    );
  }
  public getUsersbyTarget(data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}targetmapping/getUsersbyTarget/`,
      data,
      { observe: "response" }
    );
  }
  public getAllactiverolecreate(params): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}roleaccess/getalllist/` + params.searchVal,

      { observe: "response" }
    );
  }
  public runSourceSyncJob(sourceData: any, jobId: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}Source/runSync/` + jobId,
      sourceData,
      { observe: "response" }
    );
  }
  public runTargetSyncJob(sourceData: any, jobId: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}endpoint/runSync/` + jobId,
      sourceData,
      { observe: "response" }
    );
  }

  public runUserCrateSyncJob(roleData: any, jobId: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}roleaccess/runCrateUserSync/` + jobId,
      roleData,
      { observe: "response" }
    );
  }

  public runAssignRoleSyncJob(roleData: any, jobId: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}roleaccess/runAssignRoleSync/` + jobId,
      roleData,
      { observe: "response" }
    );
  }

  public scheduleSourceSyncJob(sourceData: any, jobId: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}Source/scheduleSync/` + jobId,
      sourceData,
      { observe: "response" }
    );
  }
  public scheduleTargetSyncJob(sourceData: any, jobId: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}endpoint/scheduleSync/` + jobId,
      sourceData,
      { observe: "response" }
    );
  }
  public getAllJobs(clientID, data): Observable<any> {
    return this.httpClient.put<any>(`${this.baseURL}managejobs/jobs/`, data, {
      observe: "response",
    });
  }

  public getAllRunJobsHistory(jobId: any, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}managejobs/runJobsHistory/` + data.id,
      data,
      { observe: "response" }
    );
  }
  public getAllScheduleJobsHistory(clientId: any, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}managejobs/scheduleJobsHistory/` + data.id,
      data,
      { observe: "response" }
    );
  }

  public assignRolesToUser(targetData: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}requestaccess`,
      targetData,
      { observe: "response" }
    );
  }

  public assignRolesToMultiUser(targetData: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}requestaccess/createformulti`,
      targetData,
      { observe: "response" }
    );
  }

  public exportreport(data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}export/exportreport/`,
      data,
      { observe: "response" }
    );
  }

  public downloadReport(id: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}export/downloadReport/` +id,
      { observe: "response" }
    ); 
  }

  public delete( data : any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}notification/delete/` , data,
      { observe: "response" }
    );
  } 

  public updateisread( data : any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}notification/updateisread/` , data,
      { observe: "response" }
    );
  } 

  public updatenotification( data : any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}notification/updatenotification/` , data,
      { observe: "response" }
    );
  } 

  public notifi(): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}notification/notifi` ,
      { observe: "response" }
    );
  } 

  public getallnotification(id,data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}notification/getallnotification/`, data,
      { observe: "response" }
    );
  }

  public getReportsToDownlaod(id,data :any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}export/getReportsToDownlaod/`, data,
      { observe: "response" }
    );
  } 

  public getEmailTemplates(id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}email-template/client/` + id,
      { observe: "response" }
    );
  }
  public updateEmailTemplate(body: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}email-template/client/update`,
      body
    );
  }
  public createEmailTemplate(body: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}email-template/client/create`,
      body
    );
  }
  public deleteEmailTemplate(data: any): Observable<any> {
    // const body = { templateTitle }
    return this.httpClient.post<any>(
      `${this.baseURL}email-template/client/remove`, data

    );
  }

  public getSourceOfTruth(id: any, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}source/client/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public getSource(id: any): Observable<any> {
    return this.httpClient.get<any>(`${this.baseURL}source/` + id, {
      observe: "response",
    });
  }
  public getProductList(prodData: any): Observable<any> {
    return this.httpClient.get<any>(`${this.baseURL}product-type/list/`+prodData, {
      observe: "response",
    });
  }

  public getEmailTemplateList(clientId: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}email-template/client/` + clientId,
      { observe: "response" }
    );
  }

  public deleteSouceofTruth(sourceId: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}source/delete/` + sourceId,
      { observe: "response" }
    );
  }
  public updateSource(data: any, id: string): Observable<any> {
    return this.httpClient.put<any>(`${this.baseURL}source/` + id, data, {
      observe: "response",
    });
  }
  public updateStatus(data: any, id: string): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}source/status/` + id,
      data,
      {
        observe: "response",
      }
    );
  }
  public updateManageRoleStatus(data: any, id: string): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}roleaccess/statusUpdate/` + id,
      data,
      {
        observe: "response",
      }
    );
  }

  public testConnectionSource(targetData: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}source/test/testconnection/`,
      targetData,
      { observe: "response" }
    );
  }

  public testConnectionSourceSF(targetData: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}source/test/testconnectionsf/`,
      targetData,
      { observe: "response" }
    );
  }

  // IDM Service
  public getIdentityUsers(id: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}identity-user/client/` + id,
      {
        observe: "response",
      }
    );
  }
  public getManageIdentityUsers(id: any, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}identity-user/client/` + id,
      data,
      {
        observe: "response",
      }
    );
  }
  public getInactiveUsersList(id: any, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}identity-user/inActiveUsers/` + id,
      data,
      {
        observe: "response",
      }
    );
  }
  public getOrphanAccountsUserList(id: any, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}orphanAccounts/` + id,
      data,
      {
        observe: "response",
      }
    );
  }
  public getManageIdentityUsersEdit(id: any, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}identity-user/edit/` + id,
      data,
      {
        observe: "response",
      }
    );
  }
  public getUser(id: any): Observable<any> {
    return this.httpClient.get<any>(`${this.baseURL}identity-user/` + id, {
      observe: "response",
    });
  }

  public createUser(sourceData: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}identity-user/`,
      sourceData,
      { observe: "response" }
    );
  }

  public updateUser(data: any, id: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}identity-user/update/` + id,
      data,
      { observe: "response" }
    );
  }

  public upsertSentriRole(data: any, type: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}sentriroles/` + type,
      data,
      { observe: "response" }
    );
  }

  public deleteSentriRole(id: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}sentriroles/delete/` + JSON.stringify(id),
      { observe: "response" }
    );
  }

  public getSentriRolebyId(id: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}sentriroles/getsentrirolebyId/` + id,
      { observe: "response" }
    );
  }
  
  public getUserData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}sentriroles/getUserData`,
      { observe: "response" }
    );
  }

  public checkSentriRoleNameExist(name: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}sentriroles/checkrolenameexist/` + name,
      data,
      { observe: "response" }
    );
  }

  public getManageTorriRoles(clientID: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}sentriroles/getsentriroles/` + clientID,
      data,
      { observe: "response" }
    );
  }

  public getDatasources(clientID: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}requestaccess/getdatasources/` + clientID,
      data,
      { observe: "response" }
    );
  }

  public getDatasourcesbyClientId(clientID: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}datasource-configuration/` + clientID,
      data,
      { observe: "response" }
    );
  }

  public updateDataSourceStatus(data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}datasource-configuration/update-status/` + data['_id'],
      data,
      { observe: "response" }
    );
  }

  public getDatasourceConfigbyId(id: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}datasource-configuration/get-config/` + id,
      { observe: "response" }
    );
  }

  public createDataSourceConfig(data:any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}datasource-configuration/`,
      data,
      { observe: "response" }
    );
  }

  public updateDataSourceConfig(id:any, data:any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}datasource-configuration/update/`+id,
      data,
      { observe: "response" }
    );
  }

  public getSodUsersforAssignRoles(
    clientID: string,
    data: any
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}sentriroles/getUsers/` + clientID,
      data,
      { observe: "response" }
    );
  }

  public getJobsforAssignRoles(clientID: string, data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}sentriroles/getjobs/` + clientID,
      data,
      { observe: "response" }
    );
  }

  public getIdentityUsersforAssignRoles(
    clientID: string,
    data: any
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}sentriroles/getIdentityUsers/` + clientID,
      data,
      { observe: "response" }
    );
  }

  public assignDefaultRoles(clientID: string): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}sentriroles/assigndefaultroles/` + clientID,
      {},
      { observe: "response" }
    );
  }

  public assignSentriRoles(roleId: string, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}sentriroles/assignroles/` + roleId,
      data,
      { observe: "response" }
    );
  }

  public removeAccessforUsers(data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}sentriroles/removeaccess`,
      data,
      { observe: "response" }
    );
  }

  public deleteUser(userId: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}identity-user/delete/` + userId,
      { observe: "response" }
    );
  }

  public uploadUser(sourceData: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}identity-user/bulk-create`,
      sourceData,
      { observe: "response" }
    );
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    // import('xlsx').then(XLSX => {
    const worksheet: WorkSheet = utils.json_to_sheet(json);
    const workbook: WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
    // });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }
  public upsertPwdPolicy(data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseURL}pwdpolicy/upsertPwdPolicy`,
      data,
      { observe: "response" }
    );
  }

  public getPwdPolicy(id: String, _id): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}pwdpolicy/getPwdPolicy?id=${id}&dataId=${_id}`,
      { observe: "response" }
    );
  }

  public getAllPwdPolicy(id: String): Observable<any> {
    return this.httpClient.get<any>(
      `${this.baseURL}pwdpolicy/getAllPwdPolicy/${id}`,
      { observe: "response" }
    );
  }

  public getAllPolicyList(id: String, data: any): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseURL}pwdpolicy/getAllPolicyList/${id}`,
      data,
      { observe: "response" }
    );
  }
}
