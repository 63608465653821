import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { appInitializer } from "./app-init";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NbThemeModule,
  NbSidebarModule,
  CORPORATE_THEME,
} from "@nebular/theme";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import {
  SharedModule,
  UserRouteAccessService,
  AuthInterceptor,
  AuthExpiredInterceptor,
  AuthGuardService,
} from "./shared";

import { OAuthModule, OAuthService } from "angular-oauth2-oidc";
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from "ngx-ui-loader";
import { GridModule } from "@progress/kendo-angular-grid";
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';



// import { SessionExpirationModule } from "./session-expiration/session-expiration.module";

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: " #2d96f1",
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsOpacity: 0.5,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.rectangleBounceParty, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbColor: " #2d96f1",
  pbThickness: 2, // progress bar thickness
  logoSize: 130,
  overlayBorderRadius: "0",
  overlayColor: "rgba(40, 40, 40, 0.8)",
  blur: 2,
  logoPosition: "center-center",
  logoUrl: "/assets/images/logos/logo100.png",
  fgsColor: " #2d96f1",
  fgsPosition: "center-center",
  fgsSize: 30,
  minTime: 200,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    NbThemeModule.forRoot(
      {
        name: "corporate",
      },
      [CORPORATE_THEME]
    ),
    SharedModule.forRoot(),
    NbSidebarModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    GridModule,
    ChartsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [OAuthService],
    },
    AuthGuardService,
    UserRouteAccessService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
