import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";

import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import { environment } from "../../../environments/environment";

@Injectable()
export class AccountService {
  constructor(private http: HttpClient) {}

  baseURL: string = environment.idMgmtBaseURL;
  issuesURL: string = environment.issuer;
  idMgmtURL: string = environment.idMgmtBaseURL;
  switchEvent: Subject<any> = new Subject();

  get(): Observable<any> {
    return this.http.get(`${this.baseURL}/users/me`);
  }

  // changePassword(): Observable<any> {
  // 	//return this.http.get(`${this.baseURL}/users/me`);
  // 	window.location.href = `${this.issuesURL}/change-password`;
  // 	return
  // }

  changePassword(access_token: string) {
    window.location.href =
      `${this.issuesURL}/change-password?access_token=` + access_token;
  }

  emitSwitchEvent(event: any) {
    this.switchEvent.next(event);
  }

  changeRealm(body: any): Observable<HttpResponse<any[]>> {
    console.log("body", body);
    return this.http.post<any>(`${this.idMgmtURL}/users/change-realm`, body, {
      observe: "response"
    });
  }
}
