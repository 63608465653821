// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   hmr: false,
//   roles: ["ADMIN", "USER"],
//   baseUrl: "https://identityapi-staging.sentriapp.com/",
//   sodURL: "https://sentri-sodapi-staging.sentriapp.com/api/",
//   idMgmtBaseURL: "http://localhost:9014/api",
//   issuer: "http://localhost:9014",
//   clientId: "5e7bc735c99710d6e088804e",
//   redirectUri: "http://localhost:9040",
//   freshdeskBaseUrl: "https://sentriidentity.freshdesk.com/support/home",
//   requireHttps: false,
// };
export const environment = {
  production: true,
  hmr: false,
  roles: ["ADMIN", "USER"],
  baseUrl: "https://api.identity.troopercloud.com/",
	sodURL: "https://api-common.troopercloud.com/api/",
  idMgmtBaseURL: "https://idm.troopercloud.com/api",
  issuer: "https://idm.troopercloud.com",
  clientId: "5e9d72d9c9ace1d40d8a7c34",
  redirectUri: "https://identity.troopercloud.com",
  freshdeskBaseUrl: "https://sentriidentity.freshdesk.com/support/home",
  requireHttps: true,
  accountsUrl: "https://accounts.troopercloud.com"
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
