import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
// console.log('auth.config.ts --> environment', environment);
export const authConfig: AuthConfig = {
  	clientId: environment.clientId,
	redirectUri: environment.redirectUri,
	postLogoutRedirectUri: environment.redirectUri,
	loginUrl: environment.issuer + '/oauth/authorize',
	logoutUrl: environment.issuer + '/logout',
	scope: 'openid profile email',
	resource: '',
	rngUrl: '',
	oidc: false,
	requestAccessToken: true,
	options: null,
	issuer: environment.issuer,
	clearHashAfterLogin: true,
	tokenEndpoint: environment.issuer + '/oauth/token',
	userinfoEndpoint: environment.issuer + '/oauth/userinfo',
	responseType: 'token',
	showDebugInformation: true,
	silentRefreshRedirectUri: environment.redirectUri,
	silentRefreshMessagePrefix: '',
	silentRefreshShowIFrame: false,
	silentRefreshTimeout: 20000,
	dummyClientSecret: null,
	requireHttps: environment.requireHttps,
	strictDiscoveryDocumentValidation: false,
	customQueryParams: null,
	silentRefreshIFrameName: 'angular-oauth-oidc-silent-refresh-iframe',
	timeoutFactor: 0.75,
	sessionCheckIntervall: 3000,
	sessionCheckIFrameName: 'angular-oauth-oidc-check-session-iframe',
	disableAtHashCheck: false,
	skipSubjectCheck: false
}