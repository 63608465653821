import { Subscription } from "rxjs/Subscription";
import { Subject } from "rxjs/Subject";
// import { Subject } from 'rxjs/Subject';
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";

export class SnackBarMessage {
  message: string;
  action: string = null;
  config: MatSnackBarConfig = null;
}

@Injectable()
export class SnackBarService implements OnDestroy {
  private messageQueue: Subject<SnackBarMessage> = new Subject<
    SnackBarMessage
  >();
  private subscription: Subscription;
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  constructor(public snackBar: MatSnackBar) {
    this.subscription = this.messageQueue.subscribe(message => {
      this.snackBarRef = this.snackBar.open(
        message.message,
        message.action,
        message.config
      );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Add a message
   * @param message The message to show in the snackbar.
   * @param action The label for the snackbar action.
   * @param config Additional configuration options for the snackbar.
   */
  add(message: string, action?: string, config?: MatSnackBarConfig): void {
    if (!config) {
      config = new MatSnackBarConfig();
      config.duration = 3000;
      config.verticalPosition = "top";
      config.horizontalPosition = "right";
      config.panelClass = ["blue-snackbar"];
    }

    let sbMessage = new SnackBarMessage();
    sbMessage.message = message;
    sbMessage.action = "x";
    sbMessage.config = config;

    this.messageQueue.next(sbMessage);
  }
  warning(message: string, action?: string, config?: MatSnackBarConfig): void {
    if (!config) {
      config = new MatSnackBarConfig();
      config.duration = 3000;
      config.verticalPosition = "top";
      config.horizontalPosition = "right";
      config.panelClass = ["red-snackbar"];
    }

    let sbMessage = new SnackBarMessage();
    sbMessage.message = message;
    sbMessage.action = "x";
    sbMessage.config = config;

    this.messageQueue.next(sbMessage);
  }
}
