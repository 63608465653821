import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  forwardRef,
  ModuleWithProviders
} from "@angular/core";

import { SnackBarService } from "./snackbar.service";
import { HasAnyAuthorityDirective } from "./auth/has-any-authority.directive";
import { AccountService } from "./auth/account.service";
import { Principal } from "./auth/principal.service";
import { AwsS3UploadService } from "./aws-s3-upload.service";
import { NbMenuModule, NbSidebarModule } from "@nebular/theme";

// import {
// 	AccountService,
// 	Principal,
// 	AwsS3UploadService,
// 	HasAnyAuthorityDirective,
// 	// SnackBarService
// } from './auth/';

@NgModule({
  imports: [NbMenuModule.forRoot(), NbSidebarModule],
  declarations: [HasAnyAuthorityDirective],
  providers: [
    AccountService,
    Principal,
    SnackBarService,
    forwardRef(() => AwsS3UploadService)
  ],
  entryComponents: [],
  exports: [HasAnyAuthorityDirective, NbMenuModule, NbSidebarModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    } as ModuleWithProviders;
  }
}
