import { environment } from "../environments/environment";
import {
  OAuthService,
  AuthConfig,
  NullValidationHandler
} from "angular-oauth2-oidc";
import { authConfig } from "./shared/sso-oauth2-oidc/auth.config";

export function appInitializer(oAuthService: OAuthService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      console.log(`[APP-INIT] Initialization`);
      try {
        const url = new URL(window.location.href)
        let params = url.searchParams.get('access_token')
        if(params!=null){
          localStorage.setItem('access_token', params)
          oAuthService.configure(authConfig);
          oAuthService.setStorage(localStorage);
          oAuthService.tokenValidationHandler = new NullValidationHandler();
          oAuthService.tryLogin({
            disableOAuth2StateCheck: true
          });
          if (!oAuthService.hasValidAccessToken()) {
            return oAuthService.initImplicitFlow();
          }
  
          return resolve("");
        }
        else{
          oAuthService.configure(authConfig);
          oAuthService.setStorage(localStorage);
          oAuthService.tokenValidationHandler = new NullValidationHandler();
          oAuthService.tryLogin({
            disableOAuth2StateCheck: true
          });
          if (!oAuthService.hasValidAccessToken()) {
            return oAuthService.initImplicitFlow();
          }
  
          return resolve("");
        }
      } catch (error) {
        console.log("[Error APP-INIT] -> ", error);
        return reject();
      }
    });
  };
}
