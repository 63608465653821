import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { Router } from "@angular/router";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";

import { tap } from "rxjs/operators/tap";

import { OAuthService } from "angular-oauth2-oidc";

@Injectable({
  providedIn: "root"
})
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(private router: Router, private oauthService: OAuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              localStorage.clear();
              this.oauthService.initImplicitFlow();
              return;
            } else if (err.status == 403) {
              localStorage.clear();
              this.oauthService.logOut(false);
              return;
            } else {
              console.log("HttpErrorResponse", err);
              return;
            }
          }
        }
      )
    );
  }
}
